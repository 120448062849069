class PortalApplication {
    constructor() {
        this.servicesUrl = "https://services.scriptel.com";
        this.forceHttps = false;
        this.currentModule = false;

        this.targetAfterAuth = "/organization/";
        this.currentHash = window.location.hash;

        //Login stuff, handled by the login module.
        this.authenticated = false;
        this.sessionId = false;
        this.sessionData = false;
        this.selectedOrg = false;

        //Cached country/subdivision data.
        this.countries = {};
        this.subdivisions = {};

        //Cached user data from my account module.
        this.myAccount = false;
    }

    initialize() {
        //Leak the app handle, for debugging.
        window.app = this;

        if(this.forceHttps && window.location.protocol !== "https:") {
            window.location.protocol = "https:";
        }

        let module = this.findRoute();
        if(module) {
            this.bindModule(module);
        }

        //Top Buttons
        $("#change_org").click(() => this.showChangeOrg());
        $("#my_account").click(() => this.showMyAccount());
        $("#log_off").click(() => document.getElementById("logout_dialog").showModal());

        //Tabs
        let orgSelect = this.selectedOrg ? this.selectedOrg.id : "";
        $("#organization_tab").click(() => this.navigateTo(`/organization/${orgSelect}`));
        $("#users_tab").click(() => this.navigateTo(`/users/${orgSelect}`));
        $("#licenses_tab").click(() => this.navigateTo(`/licenses/${orgSelect}`));
        $("#devices_tab").click(() => this.navigateTo(`/devices/${orgSelect}`));
        $("#admin_tab").click(() => this.navigateTo("/admin/"));

        //Dialog Buttons
        $("#logout_dialog_yes").click(() => { this.logOut(); document.getElementById("logout_dialog").close(); });
        $("#logout_dialog_no").click(() => document.getElementById("logout_dialog").close());
        $("#change_org_switch").click(() => { document.getElementById("change_org_dialog").close(); this.navigateTo("/organization/" + $("#change_org_select").val());});
        $("#change_org_cancel").click(() => document.getElementById("change_org_dialog").close());
        $("#myaccount_nevermind").click(() => document.getElementById("myaccount_dialog").close());
        $("#myaccount_save").click(() => this.saveMyAccount());
        $("#myaccount_changepassword").click(() => { document.getElementById("myaccount_dialog").close(); this.showPasswordReset(); });
        $("#change_password_change").click(() => this.changePassword());
        $("#change_password_cancel").click(() => document.getElementById("change_password_dialog").close());
        $("#change_org_new_org").click(() => { document.getElementById("change_org_dialog").close(); this.navigateTo("/neworganization"); });
        $("#change_org_select").keypress((e) => { if(e.which == 13) { document.getElementById("change_org_dialog").close(); this.navigateTo("/organization/" + $("#change_org_select").val()); } })

        //Handle the dialog polyfill.
        $("dialog").each((idx, el) => {
            if(!el.showModal) {
                dialogPolyfill.registerDialog(el);
            }
        });
        
        //Detect a hash change, since we should navigate.
        window.addEventListener("hashchange", () => {
            if(window.location.hash != this.currentHash) {
                let module = this.findRoute();
                if(module) {
                    this.bindModule(module);
                }
                this.currentHash = window.location.hash;
            }
        }, false);

        this.getConfig();
    }

    getConfig() {
        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => this.parseXhrResult(xhr, (err, result) => {
            if(err) { return; }
            if(result.servicesUrl) {
                this.servicesUrl = result.servicesUrl;
            }
        });
        xhr.open("GET", "config.json", true);
        xhr.send();
    }

    showChangeOrg() {
        let dialog = document.getElementById("change_org_dialog").showModal();
        this.fillOrgPicker();

        if(this.sessionData.admin || this.sessionData.reseller) {
            $("#change_org_new_org").show();
        } else {
            $("#change_org_new_org").hide();
        }
    }

    showMessage(message, buttonLabel, callback) {
        let snackbar = document.getElementById("snackbar");

        let params ={
            message: message,
            timeout: 3000
        };

        if(buttonLabel) {
            params.actionText = buttonLabel;
        }
        if(callback) {
            params.actionHandler = callback;
        }

        snackbar.MaterialSnackbar.showSnackbar(params);
    }

    showPasswordReset() {
        let dialog = document.getElementById("change_password_dialog");
        dialog.showModal();
        this.fixMdlFields(dialog);
    }

    showMyAccount() {
        let dialog = document.getElementById("myaccount_dialog");
        dialog.showModal();

        $(dialog).find("input, button, select").prop("disabled", true);
        $(dialog).find(".is-focused").removeClass("is-focused");

        $("#myaccount_first_name").val("");
        $("#myaccount_last_name").val("");
        $("#myaccount_title").val("");
        $("#myaccount_phone").val("");
        $("#myaccount_email").val("");
        $("#myaccount_developer_emails").prop("checked", false);
        $("#myaccount_marketing_emails").prop("checked", false);
        $("#myaccount_locked").prop("checked", false);
        $("#myaccount_administrator").prop("checked", false);
        $("#myaccount_progress_bar").show();

        let primaryOrg = document.getElementById("myaccount_primary_org");
        primaryOrg.options.length = 0;

        if(!this.sessionData.admin) {
            $("#myaccount_locked").parent().hide();
            $("#myaccount_administrator").parent().hide();
        } else {
            $("#myaccount_locked").parent().show();
            $("#myaccount_administrator").parent().show();
        }

        $("#myaccount_error_1").hide();
        $("#myaccount_error_2").hide();

        this.fixMdlFields(dialog);

        this.get(`/users/${this.sessionData.userId}`, (err, user) => {
            $("#myaccount_progress_bar").hide();

            if(err) {
                this.showError("myaccount_error_1", err.message);
                this.showError("myaccount_error_2", err.message);
                $("#myaccount_nevermind").prop("disabled", false);
                return;
            }

            $("#myaccount_first_name").val(user.firstName);
            $("#myaccount_last_name").val(user.lastName);
            $("#myaccount_title").val(user.title);
            $("#myaccount_phone").val(user.phoneNumber);
            $("#myaccount_email").val(user.email);
            $("#myaccount_developer_emails").prop("checked", user.developerEmails);
            $("#myaccount_marketing_emails").prop("checked", user.marketingEmails);
            $("#myaccount_locked").prop("checked", user.locked);
            $("#myaccount_administrator").prop("checked", user.admin);


            user.assignedOrgs.sort((a,b) => {
                return a.name.localeCompare(b.name);
            }).forEach((org) => {
                primaryOrg.options[primaryOrg.options.length] = new Option(org.name, org.id);
                if(org.id === user.primaryOrg) {
                    primaryOrg.selectedIndex = primaryOrg.options.length - 1;
                }
            });

            this.myAccount = user;

            $(dialog).find("input, button, select").prop("disabled", false);

            this.fixMdlFields(dialog);
        });

    }

    saveMyAccount() {
        let valid = true;
        $("#myaccount_dialog input").each((idx, el) => {
            if(!el.validity.valid) {
                valid = false;
            }
        });
        if(!valid) {
            return;
        }

        $("#myaccount_dialog").find("input, button").prop("disabled", true);
        $("#myaccount_progress_bar").show();
        this.fixMdlFields(document.getElementById("myaccount_dialog"));

        let user = this.getMyAccountUser();
        this.put(`/users/${user.id}`, user, (err, result) => {
            $("#myaccount_progress_bar").hide();
            $("#myaccount_dialog").find("input, button").prop("disabled", false);
            this.fixMdlFields(document.getElementById("myaccount_dialog"));
            if(err) {
                this.showError("myaccount_error_1", err.message);
                this.showError("myaccount_error_2", err.message);
                return;
            }

            this.showMessage("Successfully saved account information.");
            document.getElementById("myaccount_dialog").close();
        });
    }

    getMyAccountUser() {
        return {
            id: this.sessionData.userId,
            firstName: $("#myaccount_first_name").val(),
            lastName: $("#myaccount_last_name").val(),
            title: $("#myaccount_title").val(),
            phoneNumber: $("#myaccount_phone").val(),
            email: $("#myaccount_email").val(),
            developerEmails: $("#myaccount_developer_emails").prop("checked"),
            marketingEmails: $("#myaccount_marketing_emails").prop("checked"),
            locked: $("#myaccount_locked").prop("checked"),
            admin: $("#myaccount_administrator").prop("checked"),
            primaryOrg: parseInt($("#myaccount_primary_org").val())
        };
    }

    changePassword() {
        let dialog = document.getElementById("change_password_dialog");
        this.fixMdlFields(dialog, true);
        if(!$("#change_password_password")[0].validity.valid || !$("#change_password_password_repeat")[0].validity.valid) {
            console.log("invalid");
            return;
        }

        let password = $("#change_password_password").val();
        let repeat = $("#change_password_password_repeat").val();
        if(password !== repeat) {
            this.showError("changepassword_error", "Passwords do not match.");
        }

        $("#changepassword_error").hide();
        $(dialog).find("input, button").prop("disabled", true);
        this.fixMdlFields(dialog, true);
        $("#changepassword_progress").show();

        let user = JSON.parse(JSON.stringify(this.myAccount));
        user.password = password;
        this.put(`/users/${user.id}`, user, (err, result) => {
            $(dialog).find("input, button").prop("disabled", false);
            this.fixMdlFields(dialog, true);
            $("#changepassword_progress").hide();

            if(err) {
                this.showError("changepassword_error", err.message);
                return;
            }

            this.showMessage("Successfully changed password.");
            document.getElementById("change_password_dialog").close();
        });
    }

    fillOrgPicker() {
        let select = document.getElementById("change_org_select");
        select.options.length = 0;

        this.get("/organizations", (err, orgs) => {
            orgs.sort((a, b) => {
                let cmp = a.name.localeCompare(b.name);
                if(cmp === 0) {
                    if(a.id === b.id) {
                        return 0;
                    }
                    return (a.id > b.id) ? -1 : 1;
                }
                return cmp;
            }).forEach((org) => {
                select.options[select.options.length] = new Option(org.name, org.id);
                if(this.selectedOrg && org.id === this.selectedOrg.id) {
                    select.selectedIndex = select.options.length - 1;
                }
            });
        });
    };

    logOut() {
        //Send a message to the server to invalidate this session.
        this.post("/auth/logout", false, (err,result) => { });

        this.authenticated = false;
        this.sessionId = false;
        this.sessionData = false;
        this.selectedOrg = false;
        localStorage.removeItem("portal.session.id");
        localStorage.removeItem("portal.session.data");

        this.navigateTo("/login");
    }

    navigateTo(to) {
        if(this.currentHash !== `#${to}`) {
            this.currentHash = `#${to}`;
            window.location.hash = this.currentHash;
            let module = this.findRoute();
            this.bindModule(module);
        }
    }

    changeOrg(org) {
        if(typeof org === "object") {
            //We're getting passed an actual org
            this.selectedOrg = org;
        } else {
            //We're just getting passed a number, gotta look it up.
            let filter = this.sessionData.assignedOrgs.filter((o) => o.id === org);
            if(filter.length > 0) {
                this.selectedOrg = filter[0];
            } else {
                this.selectedOrg = false;
            }
        }
        if(this.selectedOrg) {
            $("#current_org").text(this.selectedOrg.name);
        } else {
            $("#current_org").text("Unknown");
        }
    }

    findRoute() {
        if(window.location.hash === "") {
            this.currentHash = "#/login";
            window.location.hash = this.currentHash;
        }
        let path = window.location.hash.substring(1);
        let mods = PortalApplication.modules.filter((module) => {
            let found = false;
            module.patterns.forEach((pattern) => {
                if(path.search(pattern) >= 0) {
                    found = true;
                }
            })
            return found;
        });
        return (mods.length > 0) ? mods[0] : false;
    }

    bindModule(module) {
        //Unbind the current module.
        if(this.currentModule) {
            this.currentModule.unbind();
            this.currentModule = false;
        }

        let template = new Template(document.getElementById(module.type + "_template"));
        let copy = template.clone();
        this.currentModule = module;
        module.bind(this, copy);

        //Since we're doing stuff dynamically, make sure MDL gets the notification.
        componentHandler.upgradeDom();
    };

    checkAuthentication() {
        if(!this.authenticated) {
            this.targetAfterAuth = window.location.hash.substring(1);
            this.navigateTo("/login");
            return false;
        }
        return true;
    }

    resumeAfterAuth() {
        //There's a special case here, if the user doesn't already have an organization.
        if(this.sessionData.assignedOrgs.length === 0) {
            this.navigateTo("/neworganization");
        } else {
            this.navigateTo(this.targetAfterAuth);
        }
    }

    showError(id, error) {
        let el = $(`#${id}`);
        el.show();
        el[0].style.animation = "error_shake .25s cubic-bezier(0.535, 0.970, 0.605, 0.050)";
        if(Array.isArray(error)) {
            el.empty();
            let list = document.createElement("ul");
            error.forEach((err) => {
                let li = document.createElement("li");
                li.appendChild(document.createTextNode(err));
                list.appendChild(li);
            });
            el[0].appendChild(list);
        } else {
            el.text(error);
        }
        setTimeout(() => el[0].style.animation = "", 250);
    }

    parseXhrResult(xhr, callback) {
        if(xhr.readyState === 4) {
            let obj = false;
            try {
                if(typeof xhr.response === "string") {
                    obj = JSON.parse(xhr.responseText);
                } else {
                    obj = {"message": xhr.response };
                }
            } catch(ex) {
                if(xhr.responseText === "") {
                    obj = {"message": "Server sent us an empty message."};
                } else {
                    obj = {"message": xhr.responseText };
                }
            }

            if(xhr.status === 200) {
                callback(false, obj);
            } else {
                callback(obj, false);
            }
        }
    }

    noCache(add) {
        return ((add)?"&":"?") + `t=${new Date().getTime()}&r=${Math.round(Math.random() * 100000)}`;
    }

    get(uri, callback, headers) {
        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => this.parseXhrResult(xhr, callback);
        xhr.open("GET", this.servicesUrl + uri + this.noCache(uri.indexOf("?") >= 0), true);
        if(this.authenticated) {
            xhr.setRequestHeader("x-scriptel-session-id", this.sessionId);
        }
        if(headers) {
            Object.keys(headers).forEach((key) => xhr.setRequestHeader(key, headers[key]));
        }
        xhr.send();
    }

    getBinary(uri, callback, headers) {
        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => this.parseXhrResult(xhr, callback);
        xhr.open("GET", this.servicesUrl + uri + this.noCache(), true);
        xhr.responseType = "arraybuffer";
        if(this.authenticated) {
            xhr.setRequestHeader("x-scriptel-session-id", this.sessionId);
        }
        if (headers) {
            Object.keys(headers).forEach((key) => xhr.setRequestHeader(key, headers[key]));
        }
        xhr.send();
    }

    post(uri, obj, callback, headers) {
        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => this.parseXhrResult(xhr, callback);
        xhr.open("POST", this.servicesUrl + uri + this.noCache(uri.indexOf("?") >= 0), true);
        if(this.authenticated) {
            xhr.setRequestHeader("x-scriptel-session-id", this.sessionId);
        }
        xhr.setRequestHeader("Content-type", "application/json; charset=utf-8");
        //Set headers if we got em'.
        if (headers) {
            Object.keys(headers).forEach((key) => xhr.setRequestHeader(key, headers[key]));
        }
        xhr.send(JSON.stringify(obj));
    }

    put(uri, obj, callback, headers) {
        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => this.parseXhrResult(xhr, callback);
        xhr.open("PUT", this.servicesUrl + uri + this.noCache(), true);
        if(this.authenticated) {
            xhr.setRequestHeader("x-scriptel-session-id", this.sessionId);
        }
        xhr.setRequestHeader("Content-type", "application/json; charset=utf-8");
        //Set headers if we got em'.
        if (headers) {
            Object.keys(headers).forEach((key) => xhr.setRequestHeader(key, headers[key]));
        }
        xhr.send(JSON.stringify(obj));
    }

    delete(uri, callback, headers) {
        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => this.parseXhrResult(xhr, callback);
        xhr.open("DELETE", this.servicesUrl + uri + this.noCache(), true);
        if(this.authenticated) {
            xhr.setRequestHeader("x-scriptel-session-id", this.sessionId);
        }
        if (headers) {
            Object.keys(headers).forEach((key) => xhr.setRequestHeader(key, headers[key]));
        }
        xhr.send();
    }

    fixMdlFields(root, validate) {
        $(root).find("input, textarea").each((idx, el) => {
            if(el.disabled) {
                $(el).parent().addClass("is-disabled");
            } else {
                $(el).parent().removeClass("is-disabled");
            }
            if(validate && !el.validity.valid) {
                $(el).parent().addClass("is-invalid");
            } else {
                $(el).parent().removeClass("is-invalid");
            }
            if (el.readOnly) {
                $(el).parent().addClass("is-readonly");
            } else {
                $(el).parent().removeClass("is-readonly");
            }
        });

        $(root).find("input[type=\"text\"], input[type=\"password\"], input[type=\"email\"], textarea").each((idx, el) => {
            if(el.value !== "") {
                $(el).parent().addClass("is-dirty");
            } else {
                $(el).parent().removeClass("is-dirty");
            }

            if(el.readOnly) {
                $(el).parent().addClass("is-readonly");
            } else {
                $(el).parent().removeClass("is-readonly");
            }
        });

        $(root).find("input[type=\"checkbox\"]").each((idx, el) => {
            if(el.checked) {
                $(el).parent().addClass("is-checked");
            } else {
                $(el).parent().removeClass("is-checked");
            }
        });
    }
    setAutoLogin(email, password) {
        PortalApplication.modules
            .find((m) => m instanceof LoginModule)
            .autoLogin = {email: email, password: password};
    }

    getCountries(callback) {
        if(Object.keys(this.countries).length !== 0) {
            callback(false, this.countries);
        } else {
            this.get("/organizations/country", (err, result) => {
                if(err) { callback(err, false); }
                result.forEach(country => this.countries[country.alpha2] = country);
                callback(false, this.countries);
            });
        }
    }

    getCountry(alpha2, callback) {
        this.getCountries((err, countries) => {
            if(err) {
                //Problem getting countries.
                callback(err, false);
            } else if(!countries[alpha2]) {
                //Not found.
                callback(new Error(`Unable to find country with code: ${alpha2}`), false);
            } else if(countries[alpha2].subdivisions.length !== 0) {
                //Cached already, return what we've got.
                callback(false, countries[alpha2]);
            } else {
                //Not cached yet, pull from server.
                this.get(`/organizations/country/${alpha2}`, (err, result) => {
                    if(err) {
                        callback(err, false);
                    } else {
                        countries[alpha2] = result;
                        callback(false, result);
                    }
                });
            }
        });
    }
}
PortalApplication.modules = [];