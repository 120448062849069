class PortalBootstrap {
    constructor(minimized) {
        this.minimized = minimized;
        this.scripts = {
            "full" : [
                "scripts/dialog-polyfill.js",
                "scripts/jquery-3.3.1.js",
                "scripts/material.js",
                "scripts/template.js",
                "scripts/portal-app.js",
                "scripts/flextable.js",
                "scripts/paginatedFlexTable.js",
                "scripts/flexTableHeader.js",
                "scripts/moment.js",
                "scripts/pikaday.js"
            ],
            "minimized" : [
                "scripts/portal.min.js"
            ]
        };

        this.styles = {
            "full": [
                "styles/dialog-polyfill.css",
                "styles/material.css",
                "styles/style.css",
                "styles/flextable.css",
                "styles/pikaday.css"
            ],
            "minimized": [
                "styles/portal.min.css"
            ]
        };

        this.templates = {
            "full": [
            ],
            "minimized": [
                "templates/templates.min.html"
            ]
        };

        this.modules = ["login", "organization", "users", "licenses", "devices", "admin", "newaccount", "forgotpassword", "deviceinfo", "releases", "products", "bugreports", "licensemgmt", "neworganization", "glassinfo", "invoiceinfo", "productiontool", "verifyemail", "warrantyclaim"];
        //These only add things in non-minimized mode.
        this.modules.forEach((module) => {
            this.scripts.full.push("scripts/" + module + ".module.js");
            this.styles.full.push("styles/" + module + ".module.css");
            this.templates.full.push("templates/" + module + ".module.html");
        });

        this.currentStep = 0;
        this.stepCount = 0;
        this.progressBar = false;
        this.step = false;
        this.lastStep = "";
        this.isDone = false;
    }

    contentLoaded() {
        this.progressBar = document.getElementById("splash_screen_progress_filled");
        this.step = document.getElementById("splash_screen_step");
        this.load();
    }

    done() {
        this.isDone = true;
        let splash = document.getElementById("splash_screen");
        splash.parentNode.removeChild(splash);

        //We need to fix template nodes.
        Template.rewriteNodes();

        let app = new PortalApplication();
        app.initialize();
    }

    load() {
        let styles = this.styles[this.minimized ? "minimized" : "full"];
        styles.forEach((source) => {
            let link = document.createElement("link");
            link.addEventListener("load", () => this.assetLoaded(source), false);
            link.setAttribute("rel", "stylesheet");
            link.setAttribute("type", "text/css");
            link.setAttribute("href", source);
            document.head.appendChild(link);
            this.stepCount++;
        });

        let scripts = this.scripts[this.minimized ? "minimized" : "full"];
        scripts.forEach((source) => {
            let script = document.createElement("script");
            script.addEventListener("load", () => this.assetLoaded(source), false);
            script.setAttribute("type", "application/javascript");
            script.setAttribute("src", source);
            script.async = false;
            document.head.appendChild(script);
            this.stepCount++;
        });

        let templates = this.templates[this.minimized ? "minimized" : "full"];
        templates.forEach((source) => {
            let http = new XMLHttpRequest();
            http.onreadystatechange = () => {
                if(http.readyState === 4 && http.status === 200) {
                    document.getElementById("templates").innerHTML += http.responseText;
                    this.assetLoaded(source);
                }
            };
            http.open("GET", source, true);
            http.send();
            this.stepCount++;
        });
    }

    setProgress(desc) {
        this.progressBar.style.width = ((this.currentStep / this.stepCount) * 100) + "%";
        if(desc !== this.lastStep) {
            while(this.step.firstChild) {
                this.step.removeChild(this.step.firstChild);
            }
            this.step.appendChild(document.createTextNode(desc));
            this.lastStep = desc;
        }

        let diff = this.stepCount - this.currentStep;
        if(diff === 0) {
            this.done();
        }
    }

    assetLoaded(asset) {
        this.currentStep++;
        this.setProgress("Loading Assets");
    }

    initialize() {
        document.addEventListener("DOMContentLoaded", () => this.contentLoaded(), false);
    }
}

//Start up the bootstrapper ASAP.
new PortalBootstrap(true).initialize();