class Template {
    constructor(node) {
        this.node = node;
    }

    clone() {
        if(this.node.content) {
            //Browser has template support.
            return document.importNode(this.node.content, true);
        } else {
            //We'll have to fake it.
            let el = document.createDocumentFragment();
            for(let i = 0; i < this.node.children.length; i++) {
                let clone = this.node.children[i].cloneNode(true);
                el.appendChild(clone);

                //Undo the ID fix.
                Template.rewriteNode(clone, "data-template-id", "id");
                Template.rewriteNode(clone, "data-template-class", "class");
            }
            return el;
        }
    };

    static rewriteNodes() {
        if(!document.createElement("template").content) {
            //We don't have template support, which means id's will be screwed up.
            let templates = document.getElementsByTagName("template");
            for(let i = 0; i < templates.length; i++) {
                for(let j = 0; j < templates[i].children.length; j++) {
                    Template.rewriteNode(templates[i].children[j], "id", "data-template-id");
                    Template.rewriteNode(templates[i].children[j], "class", "data-template-class");
                }
            }
        }
    };

    static rewriteNode(node, from, to) {
        if(node.getAttribute(from)) {
            node.setAttribute(to, node.getAttribute(from));
            node.removeAttribute(from);
        }
        for(let i = 0; i < node.children.length; i++) {
            Template.rewriteNode(node.children[i], from, to);
        }
    }
}